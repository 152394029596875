import React, { FC, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { CheckoutContextProvider } from "src/context/CheckoutContext";
import { ActiveSliderProvider } from "src/context/SliderContext";

import { loadToken } from "./api";
import { addMetaTag } from "./api/http/http";
import { GlobalStyles, theme } from "./assets";
import { EmptyCookie } from "./components/EmptyCookie/EmptyCookie";
import { AuthContextProvider } from "./context/AuthContext";
import { Router } from "./pages";
import { configureStore } from "./store";

loadToken();

const store = configureStore();

declare global {
  interface Window {
    YaPay: any; //  eslint-disable-line @typescript-eslint/no-explicit-any
    openFilter: any; //  eslint-disable-line @typescript-eslint/no-explicit-any
  }
}
const App: FC = () => {
  const [isEmptyCookie, setIsEmptyCookie] = useState(true);

  window.openFilter = function () {
    const filter = document.getElementById("filter");
    if (filter) filter.click();
  };
  if (window.location.href.includes("?")) {
    const head = document.head;
    const link = document.createElement("link");
    link.rel = "canonical";
    link.href = window.location.origin + window.location.pathname;
    link.href += link.href[link.href.length - 1] !== "/" ? "/" : "";
    const tagsAll = document.getElementsByTagName("link");
    if (
      ![].slice.call(tagsAll).filter(
        (
          l: any //  eslint-disable-line @typescript-eslint/no-explicit-any
        ) => l.rel === link.rel && l.href === link.href
      ).length
    ) {
      head.appendChild(link);
    }
  }
  const isPrerender =
    navigator.userAgent.toLowerCase().indexOf("prerender") !== -1;
  if (window.location.pathname[window.location.pathname.length - 1] !== "/") {
    if (isPrerender) {
      addMetaTag([
        {
          name: "prerender-status-code",
          content: "301",
        },
        {
          name: "prerender-header",
          content:
            `Location: ${window.location.pathname}/` + window.location.search,
        },
      ]);
    } else {
      window.location.href =
        `${window.location.pathname}/` + window.location.search;
    }
  }
  useEffect(() => {
    if (window.localStorage.getItem("isAcceptCookie")) {
      setIsEmptyCookie(false);
    }
  }, []);

  useEffect(() => {
    const initGTMScript = document.createElement("script");
    initGTMScript.src =
      "https://www.googletagmanager.com/gtm.js?id=GTM-PV74N85";
    initGTMScript.async = true;
    initGTMScript.defer = true;
    document.head.appendChild(initGTMScript);

    const digiScript = document.createElement("script");
    digiScript.src = "//cdn.diginetica.net/2415/client.js";
    digiScript.defer = true;
    digiScript.async = true;
    document.head.appendChild(digiScript);

    return () => {
      document.head.removeChild(initGTMScript);
      document.head.removeChild(digiScript);
    };
  }, []);
  return (
    <ActiveSliderProvider>
      <AuthContextProvider>
        <CheckoutContextProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Provider store={store}>
              <BrowserRouter>
                <ErrorBoundary
                  fallback={<>Error</>}
                  onError={() => (window.location.href = "/fallback/")}
                >
                  <Router />
                </ErrorBoundary>
              </BrowserRouter>
            </Provider>
          </ThemeProvider>
          {isEmptyCookie && (
            <EmptyCookie
              handleClick={() => {
                setIsEmptyCookie(false);
              }}
            />
          )}
        </CheckoutContextProvider>
      </AuthContextProvider>
    </ActiveSliderProvider>
  );
};

export default App;
