import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import { useCheckAuth } from "src/hooks/useCheckAuth";

import {
  CloseButton,
  CloseIcon,
  ModalContainer,
  Wrapper,
  LoginLink,
} from "./SaveReminder.styles";
import { SaveReminderProps } from "./SaveReminder.types";

const SaveReminder: FC<SaveReminderProps> = ({ url, onClose }) => {
  const { checkAuthAndPush } = useCheckAuth();
  const location = useLocation();

  return (
    <ModalContainer>
      <CloseButton onClick={onClose} aria-label="Закрыть">
        <CloseIcon />
      </CloseButton>

      <Wrapper>
        <p>
          Войдите в <u>личный кабинет</u>, чтобы отложенные вещи сохранились.
        </p>
        <LoginLink to={location.pathname} onClick={checkAuthAndPush}>
          Войти
        </LoginLink>
      </Wrapper>
    </ModalContainer>
  );
};

export default SaveReminder;
