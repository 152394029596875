import React, { FC, useMemo, useState, useEffect, useCallback } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { setWishlistUuid, setWishlistNoUuid } from "src/actions/wishlist";
import {
  getCheckout,
  getFavoriteProductsByIds,
  getFavoriteProductsByUuid,
  getFavoritesListByUuid,
  getIdForShare,
  removeFavorite,
} from "src/api";
import {
  FavoritesList,
  SortingModal,
  SaveReminder,
  Icons,
  ShareFavoritesLink,
} from "src/components";
import { getUserLink } from "src/components/Header/Header.utils";
import { SelectOption } from "src/components/Select/Select.types";
import { CURRENT_HOST, ROUTES } from "src/constants";
import { useControl, useIsMobile, useQueryParams } from "src/hooks";
import { useCheckAuth } from "src/hooks/useCheckAuth";
import { ApplicationStore, WishlistTypes } from "src/store";
import { LocalStorageFavoriteItem, SeoMeta } from "src/types";
import {
  generateMetaTags,
  getBasketId,
  getFavoritesFromLocalStorage,
  setFavoritesToLocalStorage,
} from "src/utils";

import {
  Container,
  HeaderContainer,
  Title,
  MobileTitle,
  TitleContainer,
  PersonalLink,
  PersonalLinkMobile,
  EmptyFavorites,
  CatalogLink,
  BackArrow,
  MockItem,
  ContainerMock,
  ShareButton,
  LinkContainer,
  MockBlock,
} from "./Favorites.styles";
import { FavoritesData } from "./Favorites.types";

const initialSortingOption = {
  value: "latest",
  label: "Недавние",
};

const FavoritesPage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const basketUuid = getBasketId() || "";
  const { isAuthorized } = useSelector((state: ApplicationStore) => state.user);
  const seoTitle = "Избранное";
  const seoDescription =
    "Избранное. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера";
  const metaTags: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);
  const wishListData = useSelector((state: ApplicationStore) => state.wishlist);
  const { userWorkFlow, userData } = useSelector(
    (state: ApplicationStore) => state.user
  );
  const { checkAuthAndPush } = useCheckAuth();

  const userLink = useMemo(() => getUserLink(isAuthorized, location.pathname), [
    isAuthorized,
    location.pathname,
  ]);

  const { setQueryParams } = useQueryParams({
    arrayFormat: "bracket",
  });

  const { isMobile } = useIsMobile();

  const {
    state: isSaveReminderOpened,
    deactivate: closeSaveReminder,
  } = useControl(true);

  const {
    state: isFavoritesSortingModalOpened,
    deactivate: closeFavoritesSortingModal,
  } = useControl();

  const [createId, setCreateId] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [favoritesData, setFavoritesData] = useState<FavoritesData>({
    items: [],
  });
  const [sorting, setSorting] = useState<SelectOption>(initialSortingOption);

  const {
    state: isOpenShareLinkModal,
    deactivate: closeShareLinkModal,
    activate: openShareLinkModal,
  } = useControl();

  const copyUrl = useMemo((): string => {
    return `${CURRENT_HOST}${ROUTES.shareFavorites}${createId}`;
  }, [createId]);

  const sortingItems = [
    { label: "Недавние", value: "latest" },
    { label: "Низкая цена", value: "price_min" },
    { label: "Высокая цена", value: "price_max" },
  ];

  const handleChangeSorting = useCallback(
    (option: SelectOption) => {
      setSorting(option);
    },
    [favoritesData, sorting.value]
  );

  const sortedFavoritesData = useMemo(() => {
    if (
      favoritesData &&
      favoritesData.items &&
      favoritesData.items.length > 0
    ) {
      const sortedItems = [...favoritesData.items];
      switch (sorting.value) {
        case "latest":
          sortedItems.sort((a, b) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          });
          break;
        case "price_min":
          sortedItems.sort((a, b) => {
            return a.new_price - b.new_price;
          });
          break;
        case "price_max":
          sortedItems.sort((a, b) => {
            return b.new_price - a.new_price;
          });
          break;
        default:
          break;
      }
      return { ...favoritesData, items: sortedItems };
    } else {
      return { items: [] };
    }
  }, [setQueryParams]);

  const removeItemTagManager = useCallback((favoriteId) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "remove-wishlist-item",
        ecommerce: {
          remove: {
            products: [
              {
                id: favoriteId,
              },
            ],
          },
        },
      },
    });
  }, []);

  const handleOpenShareModal = useCallback(() => {
    getIdForShare().then(({ data }) => {
      setCreateId(data.id);
      openShareLinkModal();
    });
  }, [userData, copyUrl, createId]);

  const handleRemoveFavorite = (favoriteId: number | string) => {
    if (basketUuid && userWorkFlow) {
      if (userWorkFlow === "authenticated") {
        removeFavorite(favoriteId, basketUuid).then(() => {
          getCheckout(basketUuid).then(({ data }) => {
            dispatch(setWishlistUuid(data.wish_list));
          });
          removeItemTagManager(favoriteId);
          getFavoriteProductsByUuid(basketUuid).then(({ data }) => {
            setFavoritesData(data);
          });
        });
      } else {
        if (wishListData?.wishListNoUuid.length > 0) {
          const newFavoritesArray = getFavoritesFromLocalStorage().filter(
            (item: LocalStorageFavoriteItem) => item.product_id !== favoriteId
          );
          setFavoritesToLocalStorage(newFavoritesArray);
          if (newFavoritesArray?.length > 0) {
            getFavoriteProductsByIds({
              products: newFavoritesArray,
            }).then(({ data }) => {
              setFavoritesData(data);
            });
            dispatch({
              type: "SET_WISHLIST_NO_UUID",
              payload: newFavoritesArray,
            });
          } else {
            setFavoritesToLocalStorage([]);
            setFavoritesData({ items: [] });
            dispatch({ type: WishlistTypes.CLEAR_NO_UUID });
          }
        }
      }
    }
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  useEffect(() => {
    if (userWorkFlow === "authenticated" && basketUuid !== "undefined") {
      getFavoriteProductsByUuid(basketUuid)
        .then(({ data }) => {
          setFavoritesData(data);
          if (data.items.length !== wishListData.wish_list.length) {
            getFavoritesListByUuid(basketUuid)
              .then(({ data: favoriteItemsList }) => {
                dispatch(setWishlistUuid(favoriteItemsList.items));
              })
              .catch((error) => {
                console.error("Error fetching favorites list:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching favorite products:", error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [userWorkFlow, basketUuid]);

  useEffect(() => {
    if (userWorkFlow && basketUuid) {
      if (userWorkFlow === "anonymous" || userWorkFlow === "hasUuid") {
        const favoritesItemsFromLocalStorage = getFavoritesFromLocalStorage();
        const favoritesData = wishListData.wishListNoUuid;
        if (
          JSON.stringify(favoritesItemsFromLocalStorage) !==
          JSON.stringify(favoritesData)
        ) {
          dispatch(setWishlistNoUuid(favoritesItemsFromLocalStorage));
        }
        if (favoritesData.length > 0) {
          getFavoriteProductsByIds({
            products: favoritesData,
          })
            .then(({ data }) => {
              setFavoritesData(data);
            })
            .finally(() => setIsLoading(false));
        } else {
          setIsLoading(false);
        }
      }
    }
  }, [userWorkFlow, wishListData]);

  const hasFavorites = favoritesData.items && favoritesData.items.length > 0;

  return (
    <>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>
      <Container>
        <MobileTitle>Избранное</MobileTitle>
        <HeaderContainer>
          {hasFavorites && (
            <>
              <LinkContainer>
                <PersonalLink to={location.pathname} onClick={checkAuthAndPush}>
                  Перейти в личный кабинет
                </PersonalLink>
                <PersonalLinkMobile
                  to={location.pathname}
                  onClick={checkAuthAndPush}
                >
                  <BackArrow />
                  Личный кабинет
                </PersonalLinkMobile>
                {isAuthorized && (
                  <ShareButton onClick={handleOpenShareModal}>
                    <Icons.Share />
                    Поделиться
                  </ShareButton>
                )}
              </LinkContainer>
            </>
          )}
          <TitleContainer>
            <Title>Избранное</Title>
          </TitleContainer>
          <MockBlock />
        </HeaderContainer>
        {isLoading ? (
          <ContainerMock>
            {new Array(20).fill(null).map((_, idx) => (
              <MockItem key={idx} />
            ))}
          </ContainerMock>
        ) : hasFavorites ? (
          <FavoritesList
            items={sortedFavoritesData.items}
            removeFavorite={handleRemoveFavorite}
          />
        ) : (
          <EmptyFavorites>
            <p>Список избранного пуст</p>
            <CatalogLink variant="black" to={ROUTES.catalog}>
              Перейти к покупкам
            </CatalogLink>
          </EmptyFavorites>
        )}
      </Container>
      {hasFavorites && isSaveReminderOpened && !isAuthorized && (
        <SaveReminder onClose={closeSaveReminder} url={userLink.url} />
      )}

      {isFavoritesSortingModalOpened && (
        <SortingModal
          onClose={closeFavoritesSortingModal}
          isLoading={isLoading}
          onChangeSorting={handleChangeSorting}
          sortingItems={sortingItems}
          sortingValue={sorting}
        />
      )}

      {isOpenShareLinkModal && (
        <ShareFavoritesLink
          onClose={closeShareLinkModal}
          copyUrl={copyUrl}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

export default FavoritesPage;
