import styled from "styled-components";

import { Flex } from "../../../../components/Flex";

import { NavLinkProps } from "./AuthHeader.types";

export const Container = styled(Flex)`
  width: 100%;
  margin-bottom: 60px;
`;

export const Heading = styled.p<NavLinkProps>`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: ${({ theme, active }): string => theme.colors.black};
  margin: 0 auto;
`;
