import styled from "styled-components";

import { BorderlessInput, Button, LinkButtonStyles } from "src/components";

import { Flex } from "../Flex";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Modal } from "../Modal";
import { Text } from "../Text";

import { CheckoutFooterProps, PromoCouponProps } from "./CheckoutModal.types";

export const ModalContainer = styled(Modal)<{ isOpen: boolean }>`
  z-index: 10000;
  background-color: ${({ theme }): string => theme.colors.white};
  display: flex;
  flex-direction: column;
  max-width: 375px;
  right: 0;
  height: 100%;
  overflow-y: hidden;
  @media (max-width: 1150px) {
    max-width: 346px;
  }
`;

export const Header = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 24px 39px;

  @media (max-width: 1150px) {
    display: none;
  }
`;

export const HeaderMobile = styled.div`
  padding: 7px 16px;
  display: none;

  @media (max-width: 1150px) {
    display: block;
  }
`;

export const BackButton = styled(IconButton)`
  &:hover {
    opacity: 0.5;
  }
`;

export const BackIcon = styled(Icons.BackArrow)`
  width: 30px;
  height: 14px;
`;

export const Title = styled.h1`
  font-family: Formular, sans-serif;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0 0 16px;
`;

export const CloseButton = styled(IconButton)`
  top: -8px;

  &:hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
`;

export const Footer = styled(Flex)<CheckoutFooterProps>`
  display: ${({ isEmpty }): string => (isEmpty ? "none" : "flex")};
  justify-content: space-between;
  flex-direction: column;
`;

export const CheckoutButton = styled(Button)`
  ${LinkButtonStyles};

  margin: 6px;
  height: 67px;
  width: auto;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 1150px) {
    margin: 6px 16px 16px 16px;
  }
`;

export const SummaryContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 23px 25px 24px;
`;

export const Summary = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
`;

export const SummaryPrice = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.01em;
`;

export const OldPrice = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-decoration-line: line-through;
  color: ${({ theme }): string => theme.colors.grey};
  margin-right: 18px;
`;

export const Empty = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }): string => theme.colors.black};
  margin: auto;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    font-weight: 500;
    margin-top: 40px;
  }
`;

export const CheckoutContainer = styled(Flex)`
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  flex-direction: column;

  @media screen and (min-width: 320px) {
    overflow-y: auto;
  }
`;

export const Promo = styled(Flex)`
  padding: 6px;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media (max-width: 1150px) {
    padding: 6px 20px;
  }
`;

export const PromoCoupon = styled(BorderlessInput)<PromoCouponProps>`
  font-size: 15px;
  width: 100%;
  line-height: 20px;
  border: none;
  padding: 0 20px;
  border: 1px solid ${({ theme }): string => theme.colors.lightGrey};
  border-right: 0;
  height: 50px;

  &::placeholder {
    font-size: 15px;
    line-height: 20px;
  }
`;
export const PromoButton = styled(Button)`
  width: 138px;
  height: 50px;
`;

export const PromoText = styled.p`
  font-size: 12px;
  line-height: 20px;
  bottom: -54px;
  left: 30px;
  height: 20px;
  z-index: 10;
  padding: 0 25px;

  @media screen and (max-width: 768px) {
    bottom: -44px;
  }
`;

export const PromoError = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: red;
  width: 100%;
  margin: 0;
  padding: 0 6px;
`;
