import React, { FC, useCallback } from "react";

import { loginUser } from "src/api";
import { CapchaComponent } from "src/components";
import { LoginForm } from "src/components/LoginForm";
import { LoginFormValues } from "src/components/LoginForm/LoginForm.types";
import { useForm } from "src/hooks";
import { AuthHeader } from "src/pages/Auth/elements";

import { ErrorCaptcha, WrapperError } from "./LoginModal.styles";
import { LoginModalProps } from "./LoginModal.types";

const LoginModal: FC<LoginModalProps> = ({ onSuccess }) => {
  const initialValues: LoginFormValues = {
    phone: "",
    token: "",
    codeLength: 11,
  };

  const { values, onChange, onPaste, setValues, errors, setErrors } = useForm(
    initialValues
  );

  const verifyCallback = (token_google: string | null) => {
    if (token_google) {
      setValues({
        token: token_google,
      });
    }
  };

  const clearToken = () => {
    setValues({
      token: "",
    });
  };

  const onSubmit = useCallback(async () => {
    const phoneValue = values.phone.replace(/[^0-9]/g, "");
    if (!values.token) {
      setErrors({
        token: "Подтвердите, что вы не робот",
      });
      return;
    }

    if (phoneValue.length !== values.codeLength || !/^\d+$/.test(phoneValue)) {
      setErrors({
        phone: "Неправильный формат",
      });
      return;
    }

    loginUser({
      phone: phoneValue,
      token: values.token,
    })
      .then(({ data }) => {
        sessionStorage.setItem("phone", phoneValue);
        sessionStorage.setItem("use_external", data.use_external);
        onSuccess?.(phoneValue);
      })
      .catch((error) => {
        setValues(initialValues);
        setErrors({
          phone: error.message,
        });
      });
  }, [values, setValues, onPaste, setErrors, onSuccess]);

  return (
    <>
      <AuthHeader />
      <LoginForm
        values={values}
        onChange={onChange}
        onSubmit={onSubmit}
        onPaste={onPaste}
        isLoading={false}
        errors={errors}
      />
      <CapchaComponent onVerify={verifyCallback} onError={clearToken} />
      {(errors.token || errors.phone) && (
        <WrapperError>
          <ErrorCaptcha>{errors.token || errors.phone}</ErrorCaptcha>
        </WrapperError>
      )}
    </>
  );
};

export default LoginModal;
