import styled from "styled-components";

export const ConfirmationTitle = styled.h3`
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.01em;
  margin: 0 auto 16px;
  text-align: center;
`;

export const ConfirmationDiscription = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding: 0;
  margin: 0 0 32px;
  & > span {
    font-weight: 500;
    white-space: nowrap;
  }
`;
