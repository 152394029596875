import InputMask from "react-input-mask";
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { BorderlessInputProps } from "./BorderlessInput.types";

const BorderlessMaskedInput = styled(InputMask)<BorderlessInputProps>`
  padding: 12px 0;
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
  border: none;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.grey};
  outline: 0;
  width: 100%;
  height: 40px;

  :focus,
  :not([value=""]) {
    border-color: ${({ theme }): string => theme.colors.black};
  }

  ${({
    theme,
    hasError,
  }): FlattenInterpolation<ThemeProps<DefaultTheme>> | false =>
    !!hasError &&
    css`
      color: ${theme.colors.red};

      ::placeholder {
        color: ${theme.colors.red};
        opacity: 1;
      }
    `};
`;

export default BorderlessMaskedInput;
