import styled from "styled-components";

import { Flex, Icons } from "..";
import { Modal } from "../Modal";

export const ModalContainer = styled(Modal)`
  z-index: 10000;
  background-color: ${({ theme }): string => theme.colors.white};
  display: flex;
  flex-direction: column;
  max-width: 770px;
  width: 100%;
  right: 0;
  height: 100%;
  overflow-y: hidden;
  @media screen and (max-width: 1890px) {
    max-width: 500px;
  }
`;

export const Title = styled.h1`
  font-family: Formular, sans-serif;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0 0 16px;
`;

export const CloseButton = styled.button`
  margin-left: auto;
  background: transparent;
  border: 0;
  outline: 0;
  &:hover {
    opacity: 0.5;
  }
`;

export const CloseIcon = styled(Icons.Close)`
  width: 14px;
  height: 14px;
`;

export const Header = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 24px 39px;
`;

export const AuthWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 0 100px;
  @media screen and (max-width: 1890px) {
    max-width: 304px;
    padding: 0;
    margin: 0 auto;
  }
`;
