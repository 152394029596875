import React, { useState, createContext, FC } from "react";

export type CheckoutContextValue = {
  isCartOpened: boolean;
  setIsCartOpened: (value: boolean) => void;
};

const INITIAL_VALUE: CheckoutContextValue = {
  isCartOpened: false,
  setIsCartOpened: (value: boolean) => {
    return;
  },
};

export const CheckoutContext = createContext<CheckoutContextValue>(
  INITIAL_VALUE
);

export const CheckoutContextProvider: FC = (props) => {
  const { children } = props;

  const [isCartOpened, setIsCartOpened] = useState(false);

  const value: CheckoutContextValue = {
    isCartOpened: isCartOpened,
    setIsCartOpened,
  };

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};
