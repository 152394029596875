import { createGlobalStyle } from "styled-components";

import { Formular, HelveticaNeueCyr, Rubik } from "./fonts";

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html, body {
        font-family: "HelveticaNeueCyr", sans-serif;
        font-size: 14px;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .inst {
        width: 0;
        margin: 0;
    }

    body {
        background-color: ${({ theme }): string => theme.colors.white};
        margin-top: 105px;

        .header--checkout {
            margin-top: -105px;
        }
    }

    body.modalOpened {
        overflow-x: hidden;
        overflow-y: auto !important;
        @media screen and (max-width: 768px) {
            overflow-y: hidden !important;
        }
        // position: fixed;
    }

    body.modalOpenedWithoutScroll {
        overflow-x: hidden;
        overflow-y: hidden !important;
    }

    .grecaptcha-badge {
        display: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: "Formular", sans-serif;
    }

    .grecaptcha-badge {
        display: none;
    }

    #root {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    ${Formular}

    ;
    ${HelveticaNeueCyr}

    ;
    ${Rubik}

    ;

    .react-datepicker {
        border: 1px solid ${({ theme }): string => theme.colors.black};
        width: 100%;
        border-radius: 0;

        &-popper {
            width: 100%;
        }

        &__triangle {
            &::before, &::after {
                display: none;
            }
        }

        &__header {
            background: transparent;
            padding: 0;
            margin: 0.4rem;
            border-bottom: 1px solid ${({ theme }): string =>
              theme.colors.lightGrey};
        }

        &__month-container {
            width: 100%;
        }

        &__day-names {
            display: flex;
            justify-content: space-between;
            margin: 1rem 0.4rem 0.4rem 0.4rem;
        }

        &__week {
            display: flex;
            justify-content: space-between;
            margin: 0.4rem;
        }

        &__day-name {
            text-transform: uppercase;
        }

        &__month {
            .react-datepicker__day {
                line-height: 1.6rem;
                border-radius: 30px;
            }
        }

        &__day--selected {
            background-color: ${({ theme }): string => theme.colors.black};
        }

        &__day--keyboard-selected {
            background-color: ${({ theme }): string =>
              theme.colors.veryLightGray};
            color: ${({ theme }): string => theme.colors.black};

            &:hover {
                background-color: ${({ theme }): string => theme.colors.grey};
            }
        }

        &__navigation--previous {
            left: unset;
            right: 34px;
        }

        &__current-month {
            font-weight: normal;
            font-size: 1rem;
            text-align: left;
            padding: 0.3rem 0.8rem 0 0.8rem;
            text-transform: capitalize;
        }
    }

    .popmechanic-disable-scroll {
        height: auto !important;
        min-height: 100%;
    }

`;

export default GlobalStyles;
