import React, { FC, useState } from "react";

import {
  AuthWrapper,
  CloseButton,
  CloseIcon,
  Header,
  ModalContainer,
} from "./AuthModal.styles";
import { AuthModalProps } from "./AuthModal.types";
import LoginModal from "./LoginModal/LoginModal";
import SingleModal from "./SingleModal/SingleModal";

const AuthModal: FC<AuthModalProps> = (props) => {
  const { onClose } = props;
  const [authStatus, setAuthStatus] = useState<"login" | "code">("login");
  const [phone, setPhone] = useState("");

  return (
    <ModalContainer className="modal" {...props} withAnimation>
      <Header>
        <CloseButton onClick={onClose} aria-label="Закрыть">
          <CloseIcon />
        </CloseButton>
      </Header>
      <AuthWrapper>
        {authStatus === "login" && (
          <LoginModal
            onSuccess={(phoneValue: string) => {
              setAuthStatus("code");
              setPhone(phoneValue);
            }}
          />
        )}
        {authStatus === "code" && (
          <SingleModal
            phone={phone}
            onSuccess={() => {
              setAuthStatus("login");
              setPhone("");
            }}
          />
        )}
      </AuthWrapper>
    </ModalContainer>
  );
};

export default AuthModal;
