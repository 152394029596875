import React, { FC, useCallback, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  authorizeUser,
  setCurrentWorkFlow,
  setUserAddresses,
  setUserData,
} from "src/actions";
import { getAddresses, getUserData, phoneAuth, setToken } from "src/api";
import { SingleInputForm } from "src/components/SingleInputForm";
import { ROUTES } from "src/constants";
import { StateType } from "src/pages/Auth/Conf/Confirmation.types";
import { ApplicationStore, WishlistTypes } from "src/store";
import {
  applyPhoneMask,
  getBasketId,
  sendUserAuthStatusToTagManager,
  sendUserRegistredEventToTagManager,
  setBasketId,
  setFavoritesToLocalStorage,
} from "src/utils";

import {
  ConfirmationDiscription,
  ConfirmationTitle,
} from "./SingleModal.styles";
import { SingleModalProps } from "./SingleModal.types";

const SingleModal: FC<SingleModalProps> = ({ phone, onSuccess }) => {
  const [error, setError] = useState<string>("");
  const dispatch = useDispatch();
  const wishListData = useSelector((state: ApplicationStore) => state.wishlist);
  const favoritesDataString = JSON.stringify(wishListData.wishListNoUuid) || "";
  const [isRegister, setRegister] = useState(false);
  const location = useLocation<StateType>();
  const history = useHistory();
  const [currencyMask, setCurrentMask] = useState("");
  const countryCode = sessionStorage.getItem("countryCode");

  const handleSubmit = useCallback(
    (value: string) => {
      const phone = sessionStorage.getItem("phone");
      const useExternal = sessionStorage.getItem("use_external");
      phoneAuth({
        sms_code: value,
        phone: phone || "",
        use_external: useExternal || "",
        basket_uuid: getBasketId() || undefined,
        wishlist: favoritesDataString,
      })
        .then(({ data }) => {
          window.localStorage.setItem("token_captcha", data.access_token);
          if (data.first_time) {
            sendUserRegistredEventToTagManager();
          }
          setToken(data.access_token);
          setBasketId(data.basket_uuid);
          dispatch(authorizeUser(data));
          onSuccess?.();
          getUserData().then(({ data: userData }) => {
            dispatch(setUserData(userData));

            if (userData?.user_id) {
              if (isRegister) {
                TagManager.dataLayer({
                  dataLayer: {
                    registration: "success",
                    userId: userData.user_id,
                  },
                });
              }

              TagManager.dataLayer({
                dataLayer: { event: "user-auth", userId: userData.user_id },
              });
            }

            if (userData?.id_1c) {
              sendUserAuthStatusToTagManager({ id_1c: userData.default_phone });
            }
            if (!userData.first_name && !userData.last_name) {
              history.push(ROUTES.personalDataCeate);
            } else if (!userData.name) {
              history.push(ROUTES.personalDataEdit);
              localStorage.setItem("CheckoutWithData", "true");
            } else if (localStorage.getItem("inCheckout")) {
              history.push({
                pathname: ROUTES.checkout,
                state: {
                  isRegister,
                },
              });
            }
          });
          getAddresses().then(({ data: addresses }) => {
            dispatch(setUserAddresses(addresses));
          });
          dispatch(
            setCurrentWorkFlow({
              userWorkFlow: "authenticated",
            })
          );
          setFavoritesToLocalStorage([]);
          dispatch({ type: WishlistTypes.CLEAR_NO_UUID });

          //document.location.reload();
        })
        .catch((error) => {
          setError(error.response.data.messages);
        });
    },
    [dispatch, isRegister]
  );

  const getPhoneMaskByContryCode = (countryCode: string | null): string => {
    switch (countryCode) {
      case "Россия +7":
        return "+_ (___) ___-__-__";
      case "Беларусь +375":
        return "+___ (__) ___-__-__";
      case "Казахстан +7":
        return "+_ (___) ___-__-__";
      case "Армения +374":
        return "+___ (__) __-__-__";
      default:
        return "+_ (___) ___-__-__";
    }
  };

  useEffect(() => {
    setCurrentMask(getPhoneMaskByContryCode(countryCode));
  }, [countryCode]);

  useEffect(() => {
    if (location.state) {
      setRegister(location.state.isRegister);
    }
  }, [location]);
  return (
    <>
      <ConfirmationTitle>Подтвердите номер</ConfirmationTitle>
      <ConfirmationDiscription>
        Введите код из СМС, которое мы отправили на номер{" "}
        <span>{applyPhoneMask(phone, currencyMask)}</span>
      </ConfirmationDiscription>
      <SingleInputForm
        inputName="code"
        placeholder="Одноразовый пароль"
        buttonText="ПРОДОЛЖИТЬ"
        onSubmit={handleSubmit}
        isCentered={true}
        inputType="number"
        inputMode="numeric"
        hasError={!!error}
        error={error}
        isRequired
        setError={setError}
      />
    </>
  );
};

export default SingleModal;
