import React, {
  useMemo,
  useEffect,
  FC,
  useState,
  useCallback,
  ChangeEvent,
  memo,
} from "react";
import TagManager from "react-gtm-module";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { setCheckout } from "src/actions";
import { checkCoupon, getCheckout } from "src/api";
import { CheckoutItem } from "src/components";
import { Loader } from "src/components/Loader";
import { ROUTES } from "src/constants";
import { useDebounce } from "src/hooks";
import { useCheckAuth } from "src/hooks/useCheckAuth";
import { ApplicationStore } from "src/store";
import { formatCurrency, getBasketId } from "src/utils";

import {
  ModalContainer,
  Header,
  HeaderMobile,
  Title,
  CloseButton,
  CloseIcon,
  BackButton,
  BackIcon,
  Footer,
  CheckoutButton,
  SummaryContainer,
  Summary,
  SummaryPrice,
  OldPrice,
  CheckoutContainer,
  Empty,
  Promo,
  PromoCoupon,
  PromoText,
  PromoError,
  PromoButton,
} from "./CheckoutModal.styles";
import { CheckoutModalProps } from "./CheckoutModal.types";

const DEBOUNCE_TIME = 1000; // 1 sec

const CheckoutModal: FC<CheckoutModalProps> = memo((props) => {
  const { onClose, isOpen } = props;
  const [promoCodeValue, setPromoCodeValue] = useState("");
  const [discount, setDiscount] = useState<number | null>(null);
  const [promoError, setPromoError] = useState("");
  const [isInputEnded, setIsInputEnded] = useState(false);
  const [productCountAvaliable, setProductCountAvaliable] = useState<boolean>(
    true
  );

  const { setAuthModalOpen } = useCheckAuth();
  const dispatch = useDispatch();
  const debounce = useDebounce();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const { items, price, resultPrice } = useSelector(
    (state: ApplicationStore) => state.checkout
  );

  const isAllItemsAvailable = useMemo(() => {
    return !items.find((item) => !item.in_stock) && productCountAvaliable;
  }, [items, productCountAvaliable]);

  const products = items.map((itemProduct) => ({
    name: itemProduct.product.name,
    id: itemProduct.product_id,
    price: itemProduct.discount_price || itemProduct.price,
    brand: itemProduct.product.brand?.name,
    category: itemProduct.section_tree
      .map((category) => category.name)
      .join("/"),
    quantity: itemProduct.quantity,
  }));

  const isAuthorized = useSelector(
    (s: ApplicationStore) => s.user.isAuthorized
  );

  const { info, userData } = useSelector(
    (state: ApplicationStore) => state.user
  );

  const promoHandleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPromoCodeValue(event.target.value);
      setPromoError("");
      setIsInputEnded(false);
    },
    []
  );

  const onCheckout = () => {
    setIsLoading(true);
    const checkoutId = getBasketId() || info?.checkoutId;
    getCheckout(checkoutId || "").then(({ data }) => {
      dispatch(setCheckout(data));
      setIsLoading(false);
      if (
        data.basket.items.find(
          (item) => !item.in_stock || item.quantity > item.in_stock_quantity
        )
      ) {
        return;
      } else {
        localStorage.setItem("inCheckout", "true");

        onClose();
        isAuthorized ? history.push(ROUTES.checkout) : setAuthModalOpen(true);
        if (products.length > 0) {
          const option = isAuthorized ? "authorized" : "not-authorized";
          localStorage.setItem("authorizedInCheckout", option);
          if (userData?.is_retail) return;
          TagManager.dataLayer({
            dataLayer: {
              event: "checkout",
              ecommerce: {
                checkout: {
                  actionField: { step: 1, option },
                  products,
                },
              },
            },
          });
        }
      }
    });
  };

  const onApplyPromocode = () => {
    if (promoCodeValue) {
      debounce(() => {
        const checkoutId = getBasketId() || info?.checkoutId;
        setPromoError("");
        setIsInputEnded(true);
        checkCoupon(checkoutId || "", promoCodeValue)
          .then((data) => {
            if (data.data.basket) {
              const resultPrice = data.data.basket.result_price;
              const price = data.data.basket.price;
              setDiscount(price - resultPrice);
            }
            if (data.data.messages) {
              setPromoError(data.data.messages);
            }

            getCheckout(checkoutId || "").then(({ data }) => {
              dispatch(setCheckout(data));
            });
          })
          .catch((error) => {
            setPromoError(error.response.data.messages);
          });
      }, DEBOUNCE_TIME);
    }
  };

  useEffect(() => {
    const userId = userData?.userId ? userData.userId : null;
    const option = isAuthorized ? "authorized" : "not-authorized";
    if (products.length > 0) {
      TagManager.dataLayer({
        dataLayer: {
          userId,
          event: "basketModal",
          ecommerce: {
            checkout: {
              actionField: { option },
              products,
            },
          },
        },
      });
    }
  }, [userData, products]);

  return (
    <>
      <ModalContainer {...props} isOpen={isOpen} withAnimation>
        <Header>
          <Title>Корзина</Title>
          <CloseButton onClick={onClose} aria-label="Закрыть">
            <CloseIcon />
          </CloseButton>
        </Header>
        <HeaderMobile>
          <BackButton onClick={onClose} aria-label="Закрыть">
            <BackIcon />
          </BackButton>
        </HeaderMobile>

        <CheckoutContainer>
          {items.length < 1 ? (
            <Empty>Ваша корзина пока пуста</Empty>
          ) : (
            items.map((item) => {
              const categories = item.section_tree
                ?.map((category) => category.name)
                .join("/");

              return (
                <CheckoutItem
                  total={items.length}
                  item={item}
                  key={item.id}
                  categories={categories}
                  isShowedCount
                  setProductAvaliableCount={setProductCountAvaliable}
                />
              );
            })
          )}
        </CheckoutContainer>
        {!!items.length && (
          <>
            <Promo>
              <PromoCoupon
                value={isInputEnded ? "" : promoCodeValue}
                type="text"
                name="promo"
                id="promo"
                isInputEnded={isInputEnded}
                placeholder={
                  isInputEnded ? "Ввести другой промокод" : "Промокод"
                }
                aria-label="промокод"
                onChange={promoHandleChange}
              />
              <PromoButton variant="white" onClick={onApplyPromocode}>
                Применить
              </PromoButton>
            </Promo>
            <PromoError>{promoError}</PromoError>
            {!!discount && (
              <PromoText>Ваша скидка: {formatCurrency(discount)}</PromoText>
            )}
          </>
        )}
        <Footer isEmpty={items.length < 1}>
          <SummaryContainer>
            <Summary>Итого:</Summary>
            <SummaryPrice>
              {resultPrice < price && (
                <OldPrice>{formatCurrency(price)}</OldPrice>
              )}
              {formatCurrency(resultPrice)}
            </SummaryPrice>
          </SummaryContainer>
          <CheckoutButton
            onClick={onCheckout}
            disabled={!isAllItemsAvailable || isLoading}
          >
            {isLoading ? <Loader /> : "Оформить"}
          </CheckoutButton>
        </Footer>
      </ModalContainer>
    </>
  );
});

export default CheckoutModal;
