import React, {
  useState,
  useEffect,
  FC,
  useRef,
  useCallback,
  useContext,
  MouseEvent,
  useMemo,
} from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";

import { setCheckout, setCurrentWorkFlow } from "src/actions";
import {
  getProduct,
  addToCheckout,
  getDeliveryInfo,
  getCheckout,
  getLastViewsProductsByIds,
  getLastViewsProductsByUuid,
  addToCheckoutWithoutUuid,
} from "src/api";
import { Product, Preloader } from "src/components";
import CarouselNextArrow from "src/components/Carousel/CarouselNextArrow";
import CarouselPrevArrow from "src/components/Carousel/CarouselPrevArrow";
import { ProductListNew } from "src/components/ProductListNew";
import { ROUTES } from "src/constants";
import { CheckoutContext } from "src/context/CheckoutContext";
import { usePreloaderTimer } from "src/hooks/usePreloaderTimer";
import { ApplicationStore } from "src/store";
import {
  ProductItem,
  DeliveryInfo,
  SeoMeta,
  SectionTree,
  CatalogLastViewsItem,
  LocalStorageFavoriteItem,
} from "src/types";
import {
  convertImageUrl,
  getBasketId,
  setBasketId,
  getLastViewsFromLocalStorage,
  sendTagManagerAddToCheckoutData,
  sendTagManagerGarderoboAddToCheckoutData,
} from "src/utils";

import {
  Container,
  GarderoboWrapper,
  ProductListContainer,
  ProductListTitle,
  TabletMenu,
} from "./Product.styles";
import { ProductRouteParams } from "./Product.types";

const ProductPage: FC = () => {
  const { id } = useParams<ProductRouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const { userWorkFlow, info } = useSelector(
    (state: ApplicationStore) => state.user
  );
  const wishListData = useSelector((state: ApplicationStore) => state.wishlist);
  const { userData } = useSelector((state: ApplicationStore) => state.user);
  const { items } = useSelector((state: ApplicationStore) => state.checkout);

  const [item, setItem] = useState<ProductItem>();
  const [viewedItems, setViewedItems] = useState<CatalogLastViewsItem[]>([]);
  const [deliveryInfo, setDeliveryInfo] = useState<DeliveryInfo>();
  const [isLoading, setLoading] = useState(false);
  const [isInBasket, setIsInBasket] = useState(false);
  const [sizeActive, setSizeActive] = useState(0);
  const [isWithGarderobo, setWithGarderobo] = useState<boolean>(false);

  const [metaData, setMetaData] = useState<SeoMeta[]>([]);

  const checkoutId = useMemo(() => getBasketId() || info?.checkoutId, [info]);

  const [isLoaderShown, onStartFetching, onEndFetching] = usePreloaderTimer();
  const { setIsCartOpened } = useContext(CheckoutContext);

  function handlePopstate() {
    window.sessionStorage.setItem("goBack", "true");
  }

  const [isInFavourite, setIsInFavourite] = useState(false);

  const checkIfProductIsFavorite = () => {
    if (userWorkFlow) {
      if (userWorkFlow === "authenticated") {
        if (item?.is_wish) {
          setIsInFavourite(true);
        }
      } else {
        if (wishListData.wishListNoUuid?.length > 0) {
          const isFavoriteInLocalStorage = wishListData.wishListNoUuid.some(
            (x: LocalStorageFavoriteItem) => x.product_id === item?.product.id
          );
          if (isFavoriteInLocalStorage) {
            setIsInFavourite(true);
          }
        }
      }
    }
  };

  const setMetaTags = (item: ProductItem) => {
    if (item) {
      setMetaData([
        {
          name: "description",
          content: `${item.seo.description}`,
        },
        {
          property: "og:title",
          content: `${item.seo.title}`,
        },
        {
          property: "og:description",
          content: `${item.seo.description}`,
        },
        {
          property: "og:image",
          content:
            item.product.gallery[0] &&
            convertImageUrl(item.product.gallery[0], 1200, 630),
        },
      ]);
    }
  };

  function size1c(
    item: any //  eslint-disable-line @typescript-eslint/no-explicit-any
  ) {
    if (item.product.offers.length) {
      return sizeActive
        ? item.product.offers.find((i: any) => +i.size_id === sizeActive) //  eslint-disable-line @typescript-eslint/no-explicit-any
            ?.size_id_1c
        : item.product.offers[0].size_id_1c;
    }
    return "ONE_SIZE";
  }

  const smartRef = useRef(null as HTMLElement | null);

  const addClickEventToAddedElements = useCallback((addedNodes) => {
    addedNodes.forEach((node: Node) => {
      if (node instanceof HTMLElement) {
        const isWidget =
          node.classList.contains("garderobo-widget-feed") &&
          node.getAttribute("data-id") === "garderobo-total-looks";
        if (isWidget) {
          setWithGarderobo(true);
        }
        const widgetElements = node.querySelectorAll(
          ".garderobo-widget-feed-item"
        );
        const elements = node.querySelectorAll(
          ".garderobo-widget-popup-list-item-text-cart-btn, .garderobo-widget-add-to-cart-btn"
        );
        widgetElements.forEach((element) => {
          element.addEventListener("click", handleAddDataLayerClick);
        });
        elements.forEach((element) => {
          element.addEventListener("click", handleClick);
        });
      }
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const findProductByIdInData = useCallback((itemId: string, items: any) => {
    if (items) {
      return items.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => {
          return item.product_id === parseInt(itemId);
        }
      );
    }
    return null;
  }, []);

  const handleAddDataLayerClick = (event: Event) => {
    const dataLookId = (event.currentTarget as Element).getAttribute(
      "data-look"
    );
    TagManager.dataLayer({
      dataLayer: {
        event: "widzhet",
        name: "soberi-obraz",
        content: dataLookId ?? "",
        userId: userData?.userId ?? "",
      },
    });
  };

  const handleClick = useCallback(
    (event: Event) => {
      const target = event.target as HTMLElement;
      if (target) {
        const filteredProductId =
          target.getAttribute("data-product-id") ||
          target.getAttribute("data-product");
        const filteredOfferId =
          target.getAttribute("data-offer-id") ||
          target.getAttribute("data-offer");
        console.log(filteredOfferId);
        const checkoutId = getBasketId();
        handleAddToCheckout(
          setIsCartOpened,
          Number(filteredProductId),
          checkoutId,
          1,
          filteredOfferId
        );
      }
    },
    [setIsCartOpened]
  );

  const handleAddToCheckout = useCallback(
    (
      toggleCheckout,
      filteredProductId,
      checkoutId,
      filteredQuantity,
      filteredOfferId
    ) => {
      if (
        !!filteredProductId &&
        !!checkoutId &&
        !!filteredQuantity &&
        !!filteredOfferId
      ) {
        if (checkoutId === "undefined" || checkoutId === null) {
          addToCheckoutWithoutUuid({
            product_id: filteredProductId,
            quantity: filteredQuantity,
            offer_id: filteredOfferId,
          }).then(({ data }) => {
            setBasketId(data.basket_uuid);
            dispatch(
              setCurrentWorkFlow({
                userWorkFlow: "hasUuid",
              })
            );
            getCheckout(data.basket_uuid, true).then(({ data }) => {
              dispatch(setCheckout(data));
              if (window.matchMedia("(min-width: 768px)").matches) {
                toggleCheckout(true);
              }
              const product = findProductByIdInData(
                filteredProductId,
                data.basket.items
              );
              sendTagManagerAddToCheckoutData({
                productId: product.product_id,
                vendorCode: product.size_id_1c,
                offer: filteredOfferId,
                productName: product.offer.name,
                productPrice: product.offer.new_price,
                productBrand: product.product.brand.name,
                category: product.section_tree
                  .map((category: SectionTree) => category.name)
                  .join("/"),
                is_retail: userData?.is_retail ?? false,
              });
            });
          });
        } else {
          addToCheckout(checkoutId, {
            product_id: filteredProductId,
            basket_uuid: checkoutId,
            quantity: filteredQuantity,
            offer_id: filteredOfferId,
          }).then(() => {
            getCheckout(checkoutId, true).then(({ data }) => {
              dispatch(setCheckout(data));
              if (window.matchMedia("(min-width: 768px)").matches) {
                toggleCheckout(true);
              }
              const product = findProductByIdInData(
                filteredProductId,
                data.basket.items
              );
              sendTagManagerAddToCheckoutData({
                productId: product.product_id,
                vendorCode: product.size_id_1c,
                offer: filteredOfferId,
                productName: product.offer.name,
                productPrice: product.offer.new_price,
                productBrand: product.product.brand.name,
                category: product.section_tree
                  .map((category: SectionTree) => category.name)
                  .join("/"),
                is_retail: userData?.is_retail ?? false,
              });
            });
          });
        }
      }
    },
    []
  );
  const handleGoToGarderobo = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (smartRef.current) {
        smartRef.current.scrollIntoView({ behavior: "smooth" });
      }
    },
    [smartRef.current]
  );

  useEffect(() => {
    if (item) {
      TagManager.dataLayer({
        dataLayer: {
          pagetype: "product",
          productId: item.product.id,
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: "detail-view",
          vendorcode: `${item.product.id_1c}#${
            size1c(item) ? size1c(item) : "ONE_SIZE"
          }`,
          ecommerce: {
            detail: {
              products: [
                {
                  name: item.product.name,
                  id: item.product.id,
                  price:
                    item.product.old_price > item.product.new_price
                      ? item.product.new_price
                      : item.product.old_price,
                  brand: item.product.brand.name,
                  category: item.section_tree
                    .map((category) => category.name)
                    .join("/"),
                },
              ],
            },
          },
        },
      });
    }
  }, [item]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  useEffect(() => {
    getDeliveryInfo().then(({ data }) => {
      setDeliveryInfo(data?.items?.[0]);
    });
  }, [setDeliveryInfo]);

  useEffect(() => {
    if (userWorkFlow && checkoutId && item) {
      if (userWorkFlow === "authenticated") {
        getLastViewsProductsByUuid(checkoutId).then(({ data }) => {
          setViewedItems(data.items.filter((x) => x.id !== item?.product?.id));
        });
      } else {
        const lastViews = getLastViewsFromLocalStorage();
        if (lastViews.length > 0) {
          getLastViewsProductsByIds(lastViews).then(({ data }) => {
            setViewedItems(
              data.items.filter((x) => x.id !== item?.product?.id)
            );
          });
        }
      }
    }
  }, [checkoutId, userWorkFlow, item]);

  useEffect(() => {
    checkIfProductIsFavorite();
  }, [userWorkFlow, item, wishListData]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopstate);
  }, []);
  useEffect(() => {
    setLoading(true);
    onStartFetching(500);
    getProduct(id, checkoutId)
      .then(({ data }) => {
        setItem(data);
        setIsInBasket(data.is_in_basket);
        setMetaTags(data);
      })
      .catch((error) => {
        const userAgent = navigator.userAgent;
        const isPrerender = userAgent.includes("prerender");
        if (!isPrerender) {
          history.push("/page404/");
        }
        const errorCode = error?.response?.status;

        onEndFetching();
        if (errorCode === 400 || errorCode === 404) {
          const newArr = [...metaData];

          const hasRedirect = newArr.find(
            (meta) => meta.name === "prerender-status-code"
          );
          if (!hasRedirect) {
            newArr.push({
              name: "prerender-status-code",
              content: "404",
            });
            newArr.push({
              name: "prerender-header",
              content: ROUTES.catalog,
            });
            setMetaData(newArr);
          }
        }
      })
      .finally(() => {
        setLoading(false);
        onEndFetching();
      });
  }, [
    checkoutId,
    history,
    id,
    onEndFetching,
    onStartFetching,
    setItem,
    setLoading,
  ]);

  useEffect(() => {
    if (smartRef.current) {
      const observer = new MutationObserver((mutationsList, observer) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
            addClickEventToAddedElements(mutation.addedNodes);
          }
        });
      });
      observer.observe(document, { childList: true, subtree: true });
      return () => {
        observer.disconnect();
      };
    }
  }, [smartRef.current]);

  useEffect(() => {
    sendTagManagerGarderoboAddToCheckoutData({
      offerid: JSON.stringify(items.map((item) => item.offer_id)),
    });
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet
          defer={false}
          title={item && `${item.seo.title}`}
          meta={metaData}
        />
      </HelmetProvider>
      <TabletMenu />

      {isLoaderShown && <Preloader />}

      {!!item && !isLoading && (
        <Container>
          <Product
            item={item}
            isInBasket={isInBasket}
            isInFavourite={isInFavourite}
            deliveryInfo={deliveryInfo}
            isWithGarderobo={isWithGarderobo}
            scrollToGarderobo={handleGoToGarderobo}
            onChooseSize={(id) => {
              setSizeActive(id);
            }}
          />

          <GarderoboWrapper
            id="garderobo"
            ref={smartRef as React.RefObject<HTMLDivElement>}
          ></GarderoboWrapper>

          {viewedItems &&
            viewedItems.length > 0 &&
            navigator.userAgent !== "prerender" && (
              <ProductListContainer className={"also"}>
                <ProductListTitle>Вы недавно смотрели</ProductListTitle>
                <ProductListNew
                  items={viewedItems}
                  catalogClassName={"also"}
                  nextArrowComponent={<CarouselNextArrow />}
                  prevArrowComponent={<CarouselPrevArrow />}
                  slidesToShowProps={5}
                  mobileSlidesToShowProps={2}
                  mobileShowArrow={true}
                  isViewed
                />
              </ProductListContainer>
            )}
        </Container>
      )}
    </>
  );
};

export default ProductPage;
