import gsap from "gsap";
import React, { useEffect, useMemo, FC, useRef } from "react";
import { createPortal } from "react-dom";

import { useClickOutside } from "src/hooks";

import {
  ModalWrapper,
  Container,
  AnimatedModalWrapper,
  AnimatedContainer,
} from "./Modal.styles";
import { ModalProps, PortalProps } from "./Modal.types";

const Modal: FC<ModalProps> = (props) => {
  const {
    onClose,
    children,
    className,
    withoutScroll,
    withAnimation = false,
    isOpen,
  } = props;

  const ref = useClickOutside<HTMLDivElement>(onClose);
  const modalWrapperRef = useRef(null);

  useEffect(() => {
    withoutScroll
      ? document.body.classList.add("modalOpenedWithoutScroll")
      : document.body.classList.add("modalOpened");

    return (): void => {
      withoutScroll
        ? document.body.classList.remove("modalOpenedWithoutScroll")
        : document.body.classList.remove("modalOpened");
    };
  }, []);

  useEffect(() => {
    if (!ref.current || !modalWrapperRef.current || !withAnimation) return;
    const start = gsap.timeline({ paused: true });
    start.to(modalWrapperRef.current, {
      opacity: 1,
      display: "block",
      duration: 0.3,
    });
    const end = gsap.timeline({ paused: true });
    end.to(modalWrapperRef.current, {
      opacity: 0,
      display: "none",
      duration: 0.3,
    });

    gsap.to(ref.current, {
      translateX: isOpen ? "0%" : "100%",
      duration: 0.3,
    });

    if (isOpen) {
      start.restart();
    } else {
      end.restart();
    }

    return () => {
      start.kill();
      end.kill();
    };
  }, [isOpen]);

  return (
    <>
      {withAnimation && (
        <AnimatedModalWrapper ref={modalWrapperRef}>
          <AnimatedContainer className={className} ref={ref}>
            {children}
          </AnimatedContainer>
        </AnimatedModalWrapper>
      )}
      {!withAnimation && (
        <ModalWrapper>
          <Container className={className} ref={ref}>
            {children}
          </Container>
        </ModalWrapper>
      )}
    </>
  );
};

const Portal: FC<PortalProps> = (props) => {
  const { children, rootElement = "#modal-root", ...rest } = props;

  const root = useMemo(() => document.querySelector(rootElement), [
    rootElement,
  ]);

  return root ? createPortal(<Modal {...rest}>{children}</Modal>, root) : <></>;
};

export default Portal;
