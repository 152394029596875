import React, { FC } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { CapchaContainer } from "./CapchaComponent.styles";
import { CapchaComponentProps } from "./CapchaComponent.types";

const CapchaComponent: FC<CapchaComponentProps> = ({ onVerify, onError }) => {
  return (
    <CapchaContainer>
      <ReCAPTCHA
        sitekey="6LeDjJcmAAAAAJf88E5LHqqeaF7Zh3aC4BmKJSGz"
        theme="dark"
        onChange={onVerify}
        onError={onError}
      />
    </CapchaContainer>
  );
};

export default CapchaComponent;
