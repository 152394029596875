import { ROUTES } from "../../constants";

export const getUserLink = (
  isAuthorized: boolean,
  backUrl: string,
  isMobile?: boolean
): { url: string; label: string } => {
  if (isAuthorized) {
    return {
      url: isMobile ? ROUTES.accountPage : ROUTES.personalData,
      label: "Аккаунт",
    };
  } else {
    return { url: `${ROUTES.home}?isNoAuth=1`, label: "Вход" };
  }
};

export const isMagazineItem = (url: string): boolean => {
  const urlParams = url.slice(1).split("/");
  return urlParams[0] === "magazine" && !!urlParams[1];
};
