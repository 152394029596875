import styled from "styled-components";

export const ErrorCaptcha = styled.div`
  margin-top: 12px;
  width: 304px;
  color: red;
  text-align: center;
`;

export const WrapperError = styled.div`
  display: flex;
  justify-content: space-around;
`;
