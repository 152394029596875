import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { getUserLink } from "src/components/Header/Header.utils";
import { ROUTES } from "src/constants";
import { AuthContext } from "src/context/AuthContext";
import { ApplicationStore } from "src/store";

const useCheckAuth = () => {
  const { isAuthorized } = useSelector((state: ApplicationStore) => state.user);
  const { setAuthModalOpen } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const userLink = useMemo(
    () => getUserLink(isAuthorized, location.pathname, true),
    [isAuthorized, location.pathname]
  );

  const checkAuthAndPush = () => {
    if (isAuthorized) {
      history.push(userLink.url);
    } else {
      setAuthModalOpen(true);
    }
  };

  const redirectToHome = () => {
    history.push(ROUTES.home + "?isNoAuth=1");
  };

  return { checkAuthAndPush, redirectToHome, setAuthModalOpen };
};

export default useCheckAuth;
