import React, { useContext, useMemo, FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getTopTextBanner } from "src/api";
import {
  NavMenu,
  CheckoutModal,
  MobileGlobalMenu,
  Search,
  HeaderCheckout,
  AuthModal,
} from "src/components";
import { ROUTES, PHONE } from "src/constants";
import { AuthContext } from "src/context/AuthContext";
import { CheckoutContext } from "src/context/CheckoutContext";
import { useControl, useIsMobile } from "src/hooks";
import { useCheckAuth } from "src/hooks/useCheckAuth";
import { ApplicationStore } from "src/store";
import { TextBannerItem } from "src/types";
import { formatCurrencyWithoutSymbol } from "src/utils";

import { HEADER_CLASS } from "./Header.contants";
import {
  Wrapper,
  HeaderContainer,
  LogoLink,
  Logo,
  FlexMenu,
  Banner,
  Phone,
  UserLink,
  SearchButton,
  SearchIcon,
  CheckoutButton,
  MobileLogo,
  CheckoutIcon,
  MobilePhone,
  PhoneIcon,
  MenuContainer,
  MenuButton,
  BurgerIcon,
  MobileLogoLink,
  HeadroomWrapper,
  ItemsCount,
  FavoriteLink,
  FavoriteIcon,
  UserIcon,
  UserIconLog,
  UserBonuses,
  UserBonusesTitle,
  UserBonusesValue,
  PhonePicto,
} from "./Header.styles";
import { getUserLink, isMagazineItem } from "./Header.utils";

type HeaderProps = {
  isLogged: boolean;
};
const Header: FC<HeaderProps> = ({ isLogged }) => {
  const {
    user: { isAuthorized, userWorkFlow, userData },
    checkout: { items },
    wishlist: wishListData,
  } = useSelector((state: ApplicationStore) => state);

  const { isCartOpened, setIsCartOpened } = useContext(CheckoutContext);
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(location.pathname);
  const { isAuthModalOpen, setAuthModalOpen } = useContext(AuthContext);
  const { isMobile } = useIsMobile();
  const { checkAuthAndPush } = useCheckAuth();
  const isCheckout = location.pathname.includes("/checkout");

  const userLink = useMemo(() => getUserLink(isAuthorized, location.pathname), [
    isAuthorized,
    location.pathname,
  ]);
  const itemsCount = useMemo(() => items?.length || 0, [items]);

  const wishlistCount = useMemo(() => {
    if (userWorkFlow) {
      if (userWorkFlow === "authenticated") {
        return wishListData.wish_list?.length || 0;
      } else {
        return wishListData.wishListNoUuid?.length || 0;
      }
    }
  }, [wishListData, userWorkFlow]);

  const [textBanner, setTextBanner] = useState<TextBannerItem | null>(null);

  useEffect(() => {
    getTopTextBanner()
      .then(({ data }) => {
        setTextBanner(data.items[0] || null);
      })
      .catch(() => setTextBanner(null));
  }, []);

  const {
    state: searchIsOpened,
    activate: openSearch,
    deactivate: closeSearch,
  } = useControl();

  useEffect(() => {
    if (
      !(
        prevLocation.includes(location.pathname) ||
        location.pathname.includes("/filter")
      )
    )
      window.scrollTo(0, 0);
    setPrevLocation(location.pathname);
  }, [location.pathname]);

  /* const { TODO - удалить окончательно позже если не будет нужно
      state: checkoutIsOpened,
      activate: openCheckout,
      deactivate: closeCheckout,
    } = useControl(); */

  const {
    state: menuIsOpened,
    activate: openMenu,
    deactivate: closeMenu,
  } = useControl();

  const isCompact = isMagazineItem(location.pathname);

  useEffect(() => {
    if (isLogged) {
      setAuthModalOpen(false);
    }
  }, [isLogged]);

  return (
    <>
      {isCheckout ? (
        <HeaderCheckout />
      ) : (
        <HeadroomWrapper
          className={HEADER_CLASS}
          disable={location.pathname.includes("/catalog/product") && isMobile}
          pinStart={location.pathname.includes("/brands") ? 70 : 0}
        >
          <Wrapper>
            {textBanner && !isCompact && (
              <Banner
                className="banner-top"
                dangerouslySetInnerHTML={{ __html: textBanner.detail_text }}
              />
            )}
            <HeaderContainer isCompact={isCompact}>
              <LogoLink to={ROUTES.home} aria-label="LEFORM">
                <Logo />
              </LogoLink>

              <MenuContainer>
                <MenuButton onClick={openMenu} aria-label="Открыть меню">
                  <BurgerIcon />
                </MenuButton>

                <NavMenu />

                <MobilePhone
                  href={`tel:${PHONE}`}
                  rel="noopener noreferrer"
                  aria-label="Телефон"
                >
                  <PhoneIcon />
                </MobilePhone>
              </MenuContainer>

              <MobileLogoLink to={ROUTES.home} aria-label="LEFORM">
                <MobileLogo />
              </MobileLogoLink>

              <FlexMenu alignItems="center">
                <Phone href={`tel:${PHONE}`} rel="noopener noreferrer">
                  {PHONE}
                </Phone>
                <PhonePicto
                  href={`tel:${PHONE}`}
                  rel="noopener noreferrer"
                  aria-label="Телефон"
                >
                  <PhoneIcon />
                </PhonePicto>
                <UserLink
                  role="text"
                  onClick={checkAuthAndPush}
                  aria-label={userLink.label}
                >
                  {isLogged ? <UserIconLog /> : <UserIcon />}
                  {isLogged && userData && (
                    <UserBonuses>
                      <UserBonusesTitle>Бонусы</UserBonusesTitle>
                      <UserBonusesValue>
                        {formatCurrencyWithoutSymbol(
                          userData.bonuses?.available || 0
                        )}
                      </UserBonusesValue>
                    </UserBonuses>
                  )}
                </UserLink>

                <SearchButton onClick={openSearch} aria-label="Поиск">
                  <SearchIcon />
                </SearchButton>

                <FavoriteLink to={ROUTES.favorites} aria-label="Избранное">
                  {!!wishlistCount && <ItemsCount>{wishlistCount}</ItemsCount>}
                  <FavoriteIcon />
                </FavoriteLink>

                <CheckoutButton
                  onClick={() => {
                    setIsCartOpened(true);
                  }}
                  aria-label="Корзина"
                >
                  {!!itemsCount && <ItemsCount>{itemsCount}</ItemsCount>}
                  <CheckoutIcon />
                </CheckoutButton>
              </FlexMenu>
            </HeaderContainer>

            {searchIsOpened && <Search onClose={closeSearch} />}
            <CheckoutModal
              isOpen={isCartOpened}
              onClose={() => {
                setIsCartOpened(false);
              }}
            />
            <AuthModal
              isOpen={isAuthModalOpen}
              onClose={() => {
                setAuthModalOpen(false);
              }}
            />
            {menuIsOpened && <MobileGlobalMenu onClose={closeMenu} />}
          </Wrapper>
        </HeadroomWrapper>
      )}
    </>
  );
};

export default Header;
