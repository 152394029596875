import React, { useState, createContext, FC } from "react";

export type AuthContextValue = {
  isAuthModalOpen: boolean;
  setAuthModalOpen: (value: boolean) => void;
};

const INITIAL_VALUE: AuthContextValue = {
  isAuthModalOpen: false,
  setAuthModalOpen: (value: boolean) => {
    return;
  },
};

export const AuthContext = createContext<AuthContextValue>(INITIAL_VALUE);

export const AuthContextProvider: FC = (props) => {
  const { children } = props;

  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  const value: AuthContextValue = {
    isAuthModalOpen: isAuthModalOpen,
    setAuthModalOpen,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
