import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { LinkButtonStyles } from "..";
import { Button } from "../Button";

import { FormProps } from "./SingleInputForm.types";

export const Form = styled.form<FormProps>`
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;

  input {
    text-align: ${({ isCentered }): string =>
      !!isCentered ? "center" : "left"};
  }
`;

export const SubmitButton = styled(Button)`
  ${LinkButtonStyles};
  font-size: 10px;
  margin-top: 30px;
  height: 67px;
  padding: 6px 16px 4px;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ErrorText = styled.p`
  position: absolute;
  left: 0;
  bottom: 70px;
  width: 100%;
  text-align: center;

  ${({ theme }): FlattenInterpolation<ThemeProps<DefaultTheme>> | false =>
    css`
      color: ${theme.colors.red};
    `};
`;
